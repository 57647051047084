import { BaseEntityModel } from '@/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel } from '@/model/form-control';
import { QueryPageModel } from '@/model/query-model';
import { QueryControl, QueryControlType } from '@/model/query-control';
import { alarmRuleTypeList, alarmRuleStatusList } from '@/filter/alarm';
import { AlarmRuleType, DeviceTypeEnum, PropertyEntityType } from '@/model/enum';
import { NUMBER_FLOAT_REGEXP, NUMBER_INT_REGEXP } from '@/model/regexp';
import CommonService from '@/service/common';

export class AlarmRuleEntityModel extends BaseEntityModel {
    @FormControl({
        label: '规则类型',
        type: FormControlType.RADIO_GROUP,
        options: alarmRuleTypeList,
        required: true,
        cascad: true
    } as FormControlOptionModel)
    type: string = AlarmRuleType.EVENT;

    @FormControl({
        label: '规则名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '产品',
        // labelFunction: (model: AlarmRuleEntityModel) => model.type === AlarmRuleType.EVENT ? '事件告警-产品' : '阈值告警-产品',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getDeviceTypeOptions,
        optionsPromiseParam: [DeviceTypeEnum.DEVICE],
        required: true,
        relationKeys: ['eventId', 'attributeKey']
    } as FormControlOptionModel)
    deviceTypeId: string = undefined;

    @FormControl({
        label: '规则描述',
        type: FormControlType.SELECT,
        relationPath: `${BI_BASE_REQUEST_PATH}/deviceType/[]/attrs/CLIENT_SCOPE`,
        required: true,
        invisibleFunction: model => model.type === AlarmRuleType.EVENT,
        layout: { labelCol: 10, wrapperCol: 14, width: '50% ' },
        placeholder: '请选择属性'
    } as FormControlOptionModel)
    attributeKey: string = undefined;

    @FormControl({
        label: '规则',
        type: FormControlType.SELECT,
        options: [
            { name: '大于等于', value: '>=' },
            { name: '大于', value: '>' },
            { name: '小于等于', value: '<=' },
            { name: '小于', value: '<' },
            { name: '等于', value: '=' },
            { name: '不等于', value: '!=' }
        ],
        required: true,
        invisibleFunction: model => model.type === AlarmRuleType.EVENT,
        layout: { labelCol: 0, wrapperCol: 24, width: '10.4% ' },
        placeholder: '请选择规则'
    } as FormControlOptionModel)
    operate: string = undefined;

    @FormControl({
        label: '阈值',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: model => model.type === AlarmRuleType.EVENT,
        layout: { labelCol: 0, wrapperCol: 24, width: '10.4% ' },
        placeholder: '阈值'
    } as FormControlTextModel)
    value: string = undefined;

    @FormControl({
        label: '事件',
        type: FormControlType.SELECT,
        relationPath: `${BI_BASE_REQUEST_PATH}/deviceType/[]/events`,
        required: true
    } as FormControlOptionModel)
    eventId: string = undefined;
    eventName: string = undefined;

    @FormControl({
        label: '告警级别',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getPropertyOptions,
        optionsPromiseParam: PropertyEntityType.EVENT_GRADE,
        required: true
    } as FormControlOptionModel)
    grade: string = undefined;
    gradeDesc: string = undefined;

    @FormControl({
        label: '告警周期',
        type: FormControlType.TEXT,
        pattern: NUMBER_FLOAT_REGEXP,
        suffix: '分钟',
        required: true
    } as FormControlTextModel)
    cycle: string = undefined;

    @FormControl({
        label: '几次告警',
        type: FormControlType.TEXT,
        pattern: NUMBER_INT_REGEXP,
        suffix: '次',
        required: true
    } as FormControlTextModel)
    triggerTimes: string = undefined;

    @FormControl({
        label: '通知对象',
        type: FormControlType.SELECT_USER,
        mode: 'multiple',
        required: true
    } as FormControlOptionModel)
    noticeList: Array<any> = [];

    @FormControl({
        label: '通知方式',
        type: FormControlType.CHECKBOX_GROUP,
        options: [
            { name: '微信小程序', value: 'noticeWeixin' },
            { name: '3D大屏', value: 'noticeLargeScreen' }
        ]
    } as FormControlOptionModel)
    noticeWays: Array<string> = undefined;

    @FormControl({
        label: '描述',
        type: FormControlType.TEXT_AREA
    } as FormControlTextModel)
    description: string = undefined;

    enable: boolean = undefined;

    static getTableColumns() {
        return [
            {
                title: '规则名称',
                dataIndex: 'name'
            },
            {
                title: '级别',
                dataIndex: 'gradeDesc'
            },
            {
                title: '事件',
                dataIndex: 'eventName'
            },
            {
                title: '创建时间',
                dataIndex: 'createdTime'
            },
            {
                title: '运行状态',
                dataIndex: 'enable',
                scopedSlots: { customRender: 'enable' }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        if (this.type === AlarmRuleType.THRESHOLD) {
            this.attributeKey = _.get(json, 'condition.attributeKey');
            this.operate = _.get(json, 'condition.operate');
            this.value = _.get(json, 'condition.value');
        }
        this.noticeWays = [];
        this.noticeList = _.map(json.noticeList, item => {
            return { id: item.entityId, name: item.name, isUser: item.type === 'USER' ? 'USER' : 'ORG' };
        });
        if (json.noticeWeixin) {
            this.noticeWays.push('noticeWeixin');
        }
        if (json.noticeLargeScreen) {
            this.noticeWays.push('noticeLargeScreen');
        }
        return this;
    }

    toService() {
        const data: any = super.toService();
        // data.enable = true;
        if (this.type === AlarmRuleType.THRESHOLD) {
            data.condition = {
                attributeKey: this.attributeKey,
                operate: this.operate,
                value: this.value
            };
        }
        data.noticeList = _.map(this.noticeList, item => {
            return {
                entityId: item.id,
                type: item.isUser ? 'USER' : 'ORG'
            };
        });

        data.noticeWeixin = !!_.find(this.noticeWays, item => item === 'noticeWeixin');
        data.noticeLargeScreen = !!_.find(this.noticeWays, item => item === 'noticeLargeScreen');

        delete data.attributeKey;
        delete data.operate;
        delete data.value;
        delete data.noticeWays;
        delete data.enable;
        delete data.eventName;
        delete data.gradeName;
        return data;
    }
}

export class AlarmRuleQueryModel extends QueryPageModel {
    @QueryControl({
        label: '告警级别',
        type: QueryControlType.SELECT,
        optionsPromise: CommonService.getPropertyOptions,
        optionsPromiseParam: PropertyEntityType.EVENT_GRADE,
        hasAllOption: true,
        span: 8
    })
    grade: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '运行状态',
        options: alarmRuleStatusList,
        type: QueryControlType.SELECT,
        hasAllOption: true,
        span: 8
    })
    enable: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        placeholder: '请输入告警名称',
        type: QueryControlType.TEXT,
        span: 8
    })
    name: string = undefined;

    toService() {
        return super.getParams();
    }
}
